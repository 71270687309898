const ImportMemberGetMemberStatus = {
    Initiated: 1,
    Finished: 2,
    Error: 3
};

const ImportMemberGetMemberStatusName = function(statusId) {
    if(statusId == ImportMemberGetMemberStatus.Initiated) {
        return "Processando";
    }
    else if(statusId == ImportMemberGetMemberStatus.Finished) {
        return "Finalizado";
    }
    else if(statusId == ImportMemberGetMemberStatus.Error) {
        return "Falhou";
    }
    return "";
}

export {ImportMemberGetMemberStatus, ImportMemberGetMemberStatusName};