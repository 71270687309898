<template>
  <div class="container">
    <div class="boxapp">
      <div class="boxapp-head">
        <h1>Campanhas</h1>
      </div>
      <div class="box-main">
        <div class="d-flex flex-wrap">
          <v-flex xs12 md6>
            <v-select
              v-model="campaignId"
              :items="listCampaign"
              item-text="name"
              item-value="id"
              placeholder="Campanha"
              @change="changeCampaign()"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6>
            <div class="fileUpload">
              <label for="fileUpload">{{ file.path != '' ? file.name : 'Fazer Upload' }}</label>
              <input ref="fileUpload" id="fileUpload" type="file" @change="fileChanged($event)" />
              <!-- <div v-if="file.path != ''">{{file.name}}</div> -->
              <button
                @click="upload()"
                class="btn-primary v-btn v-btn--flat theme--light white--text mx-0"
              >Enviar</button>
            </div>
          </v-flex>
        </div>

        <ul class="projects-list px-0 w-100 mt-3">
          <li v-for="mgm in listMgm" :key="mgm.id" class="layout item">
            Status: {{importMemberGetMemberStatusName(mgm.statusId)}}
            <br />
            Criado em: {{mgm.createdAt | FormatDate('DD/MM/YYYY h:mm')}}
            <br />
            Usuário: {{mgm.user.persons[0].name}}
            <br />Arquivo:
            <a @click="downloadFile(mgm.file)">{{mgm.file.name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import CampaignService from "@/scripts/services/campaign.service.js";
import PersonService from "@/scripts/services/person.service.js";
import FileService from "@/scripts/services/file.service.js";
import CommonHelper from "@/scripts/helpers/common.helper";
import File from "@/scripts/models/file.model";
import {
  ImportMemberGetMemberStatus,
  ImportMemberGetMemberStatusName,
} from "@/scripts/models/enums/importMemberGetMemberStatus.enum.js";
export default {
  data() {
    return {
      campaignService: new CampaignService(),
      personService: new PersonService(),
      fileService: new FileService(),
      importMemberGetMemberStatusName: ImportMemberGetMemberStatusName,
      file: new File(),
      campaignId: 0,
      listCampaign: [],
      fileUpload: null,
      listMgm: [],
    };
  },
  created() {
    this.loadCampaigns();
  },
  methods: {
    loadCampaigns() {
      this.campaignService
        .listAllByCompanyId(this.$route.query.companyId)
        .then(data => {this.listCampaign = data});
    },
    fileChanged(event) {
      var file = event.target.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function () {
          this.file.name = file.name;
          this.file.path = reader.result;
          this.file.path = reader.result;
        }.bind(this);
        reader.readAsDataURL(file);
      }
    },
    upload() {
      if (this.campaignId != 0 && this.file.path != "") {
        let formData = this.mountFormData();
        this.personService.uploadMemberGetMember(formData).then((data) => {
          this.changeCampaign();
        });
      } else {
        CommonHelper.swal(
          "Selecione uma campanha e faça o upload de um arquivo"
        );
      }
    },
    mountFormData() {
      let formData = new FormData();
      formData.append("streamFile", this.$refs.fileUpload.files[0]);
      formData.append("file.name", this.file.name);
      formData.append("file.path", this.file.path);
      formData.append("campaignId", this.campaignId);
      return formData;
    },
    changeCampaign() {
      this.personService.finAllMgmByCampaignId(this.campaignId).then((data) => {
        this.listMgm = data;
      });
    },
    downloadFile(file) {
      this.fileService.render(file.id).then(function (data) {
        var a = document.createElement("a");
        a.href = data;
        a.download = file.name + ".xlsx";
        a.click();
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.flex-wrap {
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);

  .flex {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.fileUpload {
  display: flex;
  height: 45px;
  width: 100%;
  margin-top: 8px;

  label {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #c3c6d5;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    border-radius: 4px 0 0 4px;
    flex-grow: 1;
  }

  button {
    height: 100%;
    border-radius: 0 4px 4px 0;
  }
}
</style>